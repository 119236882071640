<template>
  <div class="web_box">
    <van-nav-bar
      title="订单支付"
      left-arrow
      @click-left="toBack"
      fixed
      class="header_class"
      :border="false"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>
    <div class="pay_box">
      <div class="pay_status" v-if="type == 'success'">
        <div class="pay_icon success"></div>
        <p>支付成功</p>
      </div>
      <div class="pay_status" v-else>
        <div class="pay_icon error"></div>
        <p>支付失败</p>
      </div>
      <div class="pay_details">
        <li>
          <span>订单号</span>
          <span>{{ orderDeatile.orderNo }}</span>
        </li>
        <li>
          <span>支付单号</span>
          <span></span>
        </li>
        <li>
          <span>订单金额</span>
          <span>&yen; {{ orderDeatile.price }}</span>
        </li>
      </div>
    </div>
    <div class="btn success" v-if="type == 'success'">
      <button @click="toMyOrder()">确定</button>
    </div>
    <div class="btn error" v-else>
      <button @click="cancelOrder()">取消订单</button>
      <button @click="backLast()">继续支付</button>
    </div>
  </div>
</template>
<script>
import {cancelOrder} from "@/utils/api";
import {Toast, Dialog} from "vant";
export default {
  data() {
    return {
      type: "",
      orderDeatile: {},
    };
  },
  mounted() {
    let goods = JSON.parse(window.localStorage.getItem("productionitem"));
    this.orderDeatile = goods;
    this.type = this.$route.query.type;
  },
  methods: {
    toMyOrder() {
      // console.log(this.orderDeatile.orderType);
      if (this.orderDeatile.orderType == 0) {
        window.android.startMyFormulaActivity();
      } else {
        this.$router.replace("/myOrder");
      }
    },
    cancelOrder() {
      Dialog.confirm({
        title: "",
        message: "确认取消该订单？",
      })
        .then(() => {
          // 取消订单
          cancelOrder({
            orderNo: this.orderDeatile.orderNo,
          })
            .then(() => {
              Toast("订单已取消");
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    backLast() {
      this.$router.back(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.pay_box {
  background-color: #232a44;
  width: 600px;
  height: 671px;
  margin: 208px auto 0;
  .pay_status {
    padding-top: 90px;
    text-align: center;
    .pay_icon {
      margin: 0 auto;
      width: 100px;
      height: 100px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .success {
      background-image: url("../../assets/pay/success_icon.png");
    }
    .error {
      background-image: url("../../assets/pay/error_icon.png");
    }
    p {
      font-size: 32px;
      color: #fff;
      margin-top: 40px;
    }
  }
}
.pay_details {
  width: 90%;
  margin: 200px auto 0;
  position: relative;
  li {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    span:first-of-type {
      font-size: 26px;
      color: #7f89b3;
    }
    span:last-of-type {
      font-size: 26px;
      color: #8cbcd6;
    }
  }
}
.pay_details::after {
  position: absolute;
  background-color: #0b1023;
  width: 50px;
  height: 50px;
  content: "";
  border-radius: 50%;
  top: -90px;
  left: -5%;
  margin-left: -40px;
}
.pay_details::before {
  position: absolute;
  background-color: #0b1023;
  width: 50px;
  height: 50px;
  content: "";
  border-radius: 50%;
  top: -90px;
  right: -5%;
  margin-right: -40px;
}
.btn {
  margin: 60px auto 0;
  width: 600px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    font-size: 28px;
    border-radius: 112px;
    height: 100%;
    background-color: transparent;
  }
}
.success button {
  width: 100%;
  background-color: #00ccff;
  color: #fff;
}
.error button {
  width: 285px;
}
.error button:first-of-type {
  border: 1px solid #7f89b3;
  color: #7f89b3;
}
.error button:last-of-type {
  background-color: #00ccff;
  color: #fff;
}
</style>
